import React from 'react';
import dynamic from 'next/dynamic';
import type { GlobalData, PageContentProps } from './template/types';
import type { ComparativePlanData } from './template/section/comparative-plan';
import type { DefaultSectionData } from './template/section/default';
import type { SectionItemData } from './template/section/section-item';
import type { DownloadListingSectionData } from './template/section/download-listing';
import type { SimpleListingSectionData } from './template/section/simple-listing';
import type { MainListingSectionData } from './template/section/main-listing';
import type { BrandCornerData } from './template/section/brand-corner';
import { useDatalayer } from '../utils/AnalyticsContext';
import type { HomePageClubicData } from './template/section/homepage-clubic';

const SimpleListing = dynamic(() => import('./template/section/simple-listing'));
const SectionItem = dynamic(() => import('./template/section/section-item'));
const DefaultSection = dynamic(() => import('./template/section/default'));
const HomePageClubic = dynamic(() => import('./template/section/homepage-clubic'));
const ComparativePlanSection = dynamic(() => import('./template/section/comparative-plan'));
const MainListing = dynamic(() => import('./template/section/main-listing'));
const DownloadListing = dynamic(() => import('./template/section/download-listing'));
const BrandCorner = dynamic(() => import('./template/section/brand-corner'));
const ReviewListing = dynamic(() => import('./template/section/review-listing'));
const BestPickListing = dynamic(() => import('./template/section/best-pick-listing'));

export function SectionPageContent({ pageInfo, set0verrideAdUnit, data }: PageContentProps) {
  useDatalayer({ type: 'section' });

  switch (pageInfo.dataLayout) {
    case 'comparative_plan':
      return (
        <ComparativePlanSection
          pageInfo={pageInfo}
          data={data as ComparativePlanData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
        />
      );
    case 'simple_listing':
      return (
        <SimpleListing
          section={pageInfo.section}
          data={data as SimpleListingSectionData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
          pageInfo={pageInfo}
        />
      );
    case 'brand_listing':
    case 'main_listing':
      return (
        <MainListing
          section={pageInfo.section}
          data={data as MainListingSectionData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
          pageInfo={pageInfo}
        />
      );
    case 'review_listing':
      return (
        <ReviewListing
          section={pageInfo.section}
          data={data as MainListingSectionData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
          pageInfo={pageInfo}
        />
      );
    case 'best_pick_listing':
      return (
        <BestPickListing
          section={pageInfo.section}
          data={data as MainListingSectionData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
          pageInfo={pageInfo}
        />
      );
    case 'section_item':
      return (
        <SectionItem
          pageInfo={pageInfo}
          data={data as SectionItemData & GlobalData}
          set0verrideAdUnit={set0verrideAdUnit}
        />
      );
    case 'download_listing':
      return (
        <DownloadListing
          pageInfo={pageInfo}
          data={data as DownloadListingSectionData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
          section={pageInfo.section}
          set0verrideAdUnit={set0verrideAdUnit}
        />
      );
    case 'brand_corner':
      return (
        <BrandCorner
          pageInfo={pageInfo}
          data={data as BrandCornerData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
          section={pageInfo.section}
        />
      );
    case 'homepage_clubic':
      return (
        <HomePageClubic
          pageInfo={pageInfo}
          section={pageInfo.section}
          data={data as HomePageClubicData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
        />
      );
    case 'section_default':
    default:
      return (
        <DefaultSection
          section={pageInfo.section}
          data={data as DefaultSectionData & GlobalData}
          breadcrumb={pageInfo.breadcrumb}
        />
      );
  }
}
